<template>
  <v-row class="caption mt-4" no-gutters>
    <v-col cols="12" class="my-2" v-if="data.isStandard === '1'">
      <v-chip small color="green" text-color="white">
        Standard Feld:
        <span v-if="data.name === 'gender'" class="ml-1">Geschlecht</span>
        <span v-if="data.name === 'firstName'" class="ml-1">Vorname</span>
        <span v-if="data.name === 'lastName'" class="ml-1">Nachname</span>
        <span v-if="data.name === 'email'" class="ml-1">E-Mail Adresse</span>
        <span v-if="data.name === 'phone'" class="ml-1">Telefon</span>
        <span v-if="data.name === 'mobile'" class="ml-1">Mobilfunknummer</span>
        <span v-if="data.name === 'message'" class="ml-1">Nachricht</span>
        <span v-if="data.name === 'birthday'" class="ml-1">Geburtstag</span>
        <span v-if="data.name === 'street'" class="ml-1">Straße</span>
        <span v-if="data.name === 'streetNumber'" class="ml-1">Hausnummer</span>
        <span v-if="data.name === 'city'" class="ml-1">Ort</span>
        <span v-if="data.name === 'country'" class="ml-1">Land</span>
        <span v-if="data.name === 'companyName'" class="ml-1">Firmenname</span>
        <span v-if="data.name === 'reachability'" class="ml-1"
          >Erreichbarkeit</span
        >
      </v-chip>
    </v-col>

    <v-col cols="12" class="font-italic">
      <span v-if="data.type === 'input'">
        Typ: {{ data.type }} (Typ: {{ data.inputType }})
      </span>
      <span v-else> <strong>Typ:</strong> {{ data.type }}</span>
      | Variable: {{ data.name }} | Pflichtfeld:
      {{ requiredText(data.isRequired) }} | ID: {{ data.id }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "PreviewInfo",

  components: {},

  props: {
    data: { type: Object, required: true },
  },

  data() {
    return {};
  },

  created: function () {},

  methods: {
    requiredText(value) {
      if (value === "1") {
        return "Ja";
      } else {
        return "Nein";
      }
    },
  },
  computed: {},
};
</script>
