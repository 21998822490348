var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('v-row',{staticClass:"mr-0 justify-end",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"green","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Feld ")],1)]}}],null,false,776916490)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.$emit('action', {
              action: 'addField',
              value: item.value,
            })}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"orange","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" Standard Feld ")],1)]}}],null,false,2071405882)},[_c('v-list',_vm._l((_vm.selectableItems),function(item,index){return _c('v-list-item',{key:index,attrs:{"disabled":item.disabled},on:{"click":function($event){return _vm.$emit('action', {
              action: 'addStandardField',
              id: item.id,
            })}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.label + " (" + item.name + ")"))])],1)}),1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }