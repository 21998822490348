<template>
  <v-toolbar :color="stateColor()" flat dense dark>
    <v-switch
      class="mx-1 pr-0"
      v-model="data.isOnline"
      color="white"
      inset
      false-value="0"
      true-value="1"
      hide-details
      @change="
        $emit('action', {
          action: 'switchOnlineState',
          state: data.isOnline,
          id: data.id,
        })
      "
    ></v-switch>

    <v-toolbar-title class="text-overline"> {{ name }} - Feld </v-toolbar-title>
    <v-spacer />

    <v-btn
      fab
      dark
      elevation="0"
      x-small
      color="blue"
      class="white--text mx-1"
      :disabled="edit"
      @click="
        $emit('action', {
          action: 'edit',
          id: data.id,
        })
      "
    >
      <v-icon dark> mdi-pencil </v-icon>
    </v-btn>

    <v-btn
      fab
      dark
      elevation="0"
      x-small
      color="red"
      class="white--text mx-1"
      :disabled="edit"
      @click="
        $emit('action', {
          action: 'delete',
          id: data.id,
        })
      "
    >
      <v-icon dark> mdi-trash-can </v-icon>
    </v-btn>

    <v-icon v-if="!edit" class="draghandle"> mdi-drag </v-icon>
    <v-icon v-else disabled> mdi-drag </v-icon>
  </v-toolbar>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "Toolbar",

  components: {},

  data() {
    return {};
  },

  props: {
    data: { type: Object, required: true },
    edit: { type: Boolean, required: true },
    name: { type: String, required: true },
  },

  created: function() {},

  methods: {
    stateColor() {
      if (this.data.isOnline === "1") {
        return "green";
      } else {
        return "grey";
      }
    },
  },
  computed: {},
  mixins: [apiRequest, helpers],
};
</script>
