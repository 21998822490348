<template>
  <v-row v-if="data" no-gutters class="mr-0 justify-end">
    <v-col cols="auto">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="green" dark v-bind="attrs" v-on="on" class="mx-2">
            <v-icon left> mdi-plus </v-icon>
            Feld
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="
              $emit('action', {
                action: 'addField',
                value: item.value,
              })
            "
          >
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="auto">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="orange" dark v-bind="attrs" v-on="on" class="mx-2">
            <v-icon left> mdi-plus </v-icon>
            Standard Feld
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in selectableItems"
            :key="index"
            :disabled="item.disabled"
            @click="
              $emit('action', {
                action: 'addStandardField',
                id: item.id,
              })
            "
          >
            <v-list-item-title>{{
              item.label + " (" + item.name + ")"
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SelectFieldToAdd",

  components: {},

  props: {
    fields: { type: Array, required: true },
    standardFields: { type: Array, required: true },
  },

  data() {
    return {
      data: [],
      fetchinData: false,
      items: [
        {
          value: "input",
          name: "Input",
        },
        {
          value: "textarea",
          name: "Textarea",
        },
        {
          value: "select",
          name: "Auswahl",
        },
        /* {
          value: "checkbox",
          name: "Checkbox",
        }, */
        /* {
          value: "radio",
          name: "Radio",
        }, */
      ],
    };
  },

  created: function () {},

  methods: {},

  computed: {
    selectableItems() {
      /* check if any of the standard-fields is already in use.
      Every standard-field can be used only once */

      let data = this.standardFields;
      data.forEach((item) => {
        this.fields.forEach((field) => {
          if (field.name === item.name) {
            // already in fields
            item.disabled = true;
          }
        });
      });

      return data;
    },
  },
};
</script>
