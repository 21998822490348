<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
      v-if="!fetchinData"
    />

    <v-row v-if="!fetchinData">
      <v-col cols="12" md="8">
        <v-card tile>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Formular</v-toolbar-title>
            <v-spacer></v-spacer>
            <SelectFieldToAdd
              :fields="data.fields"
              :standardFields="standardFields"
              @action="addFieldEvent"
              :key="componentKey"
            />
          </v-toolbar>

          <v-card-text>
            <table class="default-table">
              <tbody>
                <tr>
                  <td style="width: 150px">Formular-Titel</td>
                  <td>
                    <Patcher
                      :itemValue="data.title"
                      itemName="title"
                      :route="patchRoute"
                      inputElement="input"
                      inputType="text"
                      selectOptions=""
                      itemValueKey=""
                      itemTextKey=""
                      :previewValue="data.title"
                      @emit="data.title = $event.value"
                    ></Patcher>
                  </td>
                </tr>
              </tbody>
            </table>

            <draggable
              v-model="data.fields"
              ghost-class="ghost"
              :move="checkMove"
              handle=".draghandle"
              @start="dragging = true"
              @end="handleDragEnd('category')"
              draggable=".is-draggable"
            >
              <template v-if="data.fields.length > 0">
                <template v-for="field in data.fields">
                  <div class="is-draggable" :key="field.id">
                    <component
                      class="my-2"
                      :is="field.type + 'Type'"
                      :data="field"
                      :standardFields="standardFields"
                      @action="fieldAction"
                    ></component>
                  </div>
                </template>
              </template>
            </draggable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <AreYouSureDialog
      title="Bist du dir sicher?"
      text="Delete Text"
      :dialog="safeguardingDialog"
      @dialogEmit="safeguarding"
    />
  </v-container>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import Patcher from "@/components/ui/Patcher";
import draggable from "vuedraggable";
import SelectFieldToAdd from "@/components/pages/forms/SelectFieldToAdd";
import checkboxType from "@/components/pages/forms/field-components/checkboxType";
import radioType from "@/components/pages/forms/field-components/radioType";
import selectType from "@/components/pages/forms/field-components/selectType";
import inputType from "@/components/pages/forms/field-components/inputType";
import textareaType from "@/components/pages/forms/field-components/textareaType";
export default {
  name: "Form",

  components: {
    PageHeader,
    AreYouSureDialog,
    Patcher,
    draggable,
    SelectFieldToAdd,
    checkboxType,
    radioType,
    selectType,
    inputType,
    textareaType,
  },

  data() {
    return {
      formUuid: this.$route.params.id,
      pageTitle: null,
      pageDescription: null,
      dragging: false,
      itemUuid: this.$route.params.id,
      safeguardingDialog: false,
      itemTitle: null,
      itemDescription: null,
      fetchinData: false, // should be true when fetching data
      data: [],
      standardFields: [],
      componentKey: 0,
      patchRoute: `forms/${this.$route.params.id}`,
      draggedItemUuid: null,
    };
  },

  props: [],

  created: function () {
    this.getData();
  },

  methods: {
    async addField(fieldType) {
      let formData = new FormData();
      formData.append("type", fieldType);

      this.preloader = true;
      let response = await this.postRequest(
        `forms/${this.formUuid}/fields`,
        "",
        formData
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Feld wurde hinzugefügt";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // Mutate
        this.data.fields.push(response.data.data);
        this.componentKey += 1;
      }
    },

    async addStandardField(id) {
      console.log("addStandardField");
      let formData = new FormData();
      formData.append("id", id);

      this.preloader = true;
      let response = await this.postRequest(
        `forms/${this.formUuid}/fields`,
        "",
        formData
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Feld wurde hinzugefügt";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // Mutate
        this.data.fields.push(response.data.data);
        this.componentKey += 1;
      }
    },

    addFieldEvent(value) {
      if (value.action === "addStandardField") {
        this.addStandardField(value.id);
      } else if (value.action === "addField") {
        this.addField(value.value);
      }
    },

    fieldAction(value) {
      if (value.action === "delete") {
        this.safeguardingDialog = true;
        this.itemToDelete = value.id;
      } else if (value === "cancel") {
        // make it reload
        this.componentKey += 1;
      }
    },

    async deleteField() {
      this.preloader = true;
      let response = await this.deleteRequest(
        `forms/${this.$route.params.id}/fields/${this.itemToDelete}`
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Feld wurde gelöscht";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        // find field to remove it from data (mutate)
        this.data.fields.forEach((field, index) => {
          if (field.id === this.itemToDelete) {
            this.data.fields.splice(index, 1); // remove field
            this.itemToDelete = null;
            this.componentKey += 1;
            this.getStandardFields();
          }
        });
      }
    },

    safeguarding(value) {
      this.safeguardingDialog = false;
      if (value) {
        this.deleteField();
      }
    },

    async getStandardFields() {
      let response = await this.getRequest(`forms/fields?filter[isTemplate]=1`);

      if (response.status === 200) {
        this.standardFields = response.data.data;
      }

      this.fetchinData = false;
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.getRequest(`forms/${this.itemUuid}`);

      if (response.status === 200) {
        this.data = response.data.data;
        this.getStandardFields(); // getting standard fields
      }
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },

    async dragUpdate(idAndsortOrder) {
      const url = `forms/${this.formUuid}/fields`;

      let response = await this.patchRequest(
        url,
        JSON.stringify(idAndsortOrder),
        "json"
      );

      if (response.status === 200) {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Die Reihenfolge wurde erfolgreich geändert.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;

        console.log("dragUpdate done");
        console.log(response.data.data.fields);
        //this.data.fields = response.data.data.fields;
      }
    },

    checkMove: function (e) {
      this.onDrag = true;
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    handleMove(e) {
      /* SWAP-Hack: https://fir-vue-draggable.firebaseapp.com/swap/code */
      this.movingIndex = e.draggedContext.index;
      this.futureIndex = e.draggedContext.futureIndex;

      console.log(e.draggedContext.uuid);
      return false; // disable sort
    },

    handleDragEnd: function () {
      /* hier loope ich den mutierten array durch und bilde
      einen neuen array aus uuid und sortOrder.
      Dieses Array schicke ich dann via POST an die API */

      this.dragging = false;

      let idAndsortOrder = [];
      let newOrderedArray = this.data.fields;

      let sortOrder = 1;

      newOrderedArray.forEach((item) => {
        const set = {
          id: item.id,
          sortOrder: sortOrder,
        };

        idAndsortOrder.push(set);
        ++sortOrder;
      });

      this.dragUpdate(idAndsortOrder);
    },
  },

  computed: {
    //
  },

  mixins: [apiRequest, helpers],
};
</script>

<style>
.draghandle {
  cursor: pointer;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: rgba(0, 0, 0, 0.01);
}
</style>
