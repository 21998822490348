<template>
  <v-card>
    <Toolbar
      :data="fieldData"
      :edit="edit"
      name="Input"
      @action="toolbarAction"
    />

    <template v-if="edit">
      <v-card-text v-if="edit">
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" v-if="fieldData.isStandard === '1'">
              <v-chip class="ma-2" color="orange" text-color="white">
                <v-icon left> mdi-information </v-icon>
                Standard-Feld**
              </v-chip>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                outlined
                v-model="inputType"
                :rules="requiredRules"
                :items="inputTypes"
                label="Feld-Typ"
                :disabled="isDisabled()"
                hide-details
              ></v-select>
            </v-col>

            <!-- <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="name"
                label="Name"
                :rules="requiredRules"
                :disabled="isDisabled()"
                hide-details
              ></v-text-field>
            </v-col> -->

            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="label"
                label="Label"
                :rules="requiredRules"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="placeholder"
                label="Platzhalter"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="hint"
                label="Hinweis"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-select
                outlined
                v-model="colSize"
                :rules="requiredRules"
                :items="colSizes"
                label="Column-Breite"
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-checkbox
                v-model="isRequired"
                true-value="1"
                false-value="0"
                label="Pflichtfeld"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" class="caption">
              *) Pflichtfeld
              <span v-if="data.isStandard === '1'"
                ><br />**) Bei Standardfeldern können Feld-Typ und Name nicht
                geändert werden.</span
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions v-if="edit">
        <v-spacer />
        <v-btn color="grey" text @click="cancelEdit()">
          Abbrechen
        </v-btn>
        <v-btn
          v-if="valid"
          color="green"
          dark
          @click="safeData"
          :loading="fetchinData"
          >Speichern</v-btn
        >
        <v-btn v-else disabled>Speichern</v-btn>
      </v-card-actions>
    </template>

    <template v-else>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="2" class="font-weight-bold align-self-center">
            {{ fieldData.label }} <span v-if="fieldData.isRequired">*</span>
          </v-col>

          <v-col cols="12" md="10" class="align-self-center">
            <v-text-field
              :type="fieldData.inputType"
              :placeholder="placeholder"
              solo
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <PreviewInfo :data="fieldData" />
      </v-card-text>
    </template>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import Toolbar from "@/components/pages/forms/field-components/assets/Toolbar";
import PreviewInfo from "@/components/pages/forms/field-components/assets/PreviewInfo";
export default {
  name: "inputType",

  components: {
    Toolbar,
    PreviewInfo,
  },

  data() {
    return {
      fieldData: this.data,
      valid: false,
      fetchinData: false,
      // fields to use
      name: this.data.name,
      label: this.data.label,
      placeholder: this.data.placeholder,
      isRequired: this.data.isRequired,
      inputType: this.data.inputType,
      inputOptions: this.data.inputOptions,
      isOnline: this.data.isOnline,
      colSize: this.data.colSize,
      hint: this.data.hint,
      // todo: reset to original
      edit: false,
      formId: this.$route.params.id,
      requiredRules: [(v) => !!v || "Field is required"],
      inputTypes: [
        "text",
        "number",
        "email",
        "password",
        "tel",
        "url",
        "color",
        "date",
        "file",
        "image",
        "hidden",
      ],
      colSizes: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
    };
  },

  props: {
    data: { type: Object, required: true },
    standardFields: { type: Array, required: true },
  },

  methods: {
    isDisabled() {
      if (this.fieldData.isStandard === "1") {
        return true;
      } else {
        return false;
      }
    },

    toolbarAction(value) {
      if (value.action === "edit") {
        this.edit = true;
      } else if (value.action === "switchOnlineState") {
        this.isOnline = value.state;
        this.safeData();
      } else {
        this.$emit("action", value);
      }
    },

    cancelEdit() {
      this.edit = false;
      // reset to original
      // todo: hier müssen die mutierten werte wieder resettet werden
      this.fieldData = this.data;
      this.label = this.data.label;
      this.name = this.data.name;
      this.placeholder = this.data.placeholder;
      this.hint = this.data.hint;
      this.isRequired = this.data.isRequired;
      this.inputType = this.data.inputType;
      this.inputOptions = this.data.inputOptions;
      this.isOnline = this.data.isOnline;
      this.colSize = this.data.colSize;
      this.$emit("action", "cancel");
    },

    async safeData() {
      // check, if (it is not a standard field)
      // the name is not a standard-field name
      let nameAllowed = true;
      this.standardFields.forEach((item) => {
        if (item.name === this.name) {
          nameAllowed = false;
        }
      });

      if (nameAllowed || this.data.isStandard === "1") {
        const params = new URLSearchParams();
        params.append("label", this.label);
        params.append("name", this.name);
        params.append("placeholder", this.placeholder);
        params.append("hint", this.hint);
        params.append("isRequired", this.isRequired);
        params.append("inputType", this.inputType);
        params.append("inputOptions", this.inputOptions);
        params.append("isOnline", this.isOnline);
        params.append("colSize", this.colSize);

        this.preloader = true;
        let response = await this.patchRequest(
          `forms/${this.formId}/fields/${this.data.id}`,
          params
        );
        this.preloader = false;

        if (response.status === 200) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText =
            "Das Feld wurde aktualisiert";
          this.$root.snackbarGlobal.snackbarColor = "green";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;

          console.log(response);
          // todo: mutate original data
          this.fieldData = response.data.data;
          this.name = response.data.data.name;
          this.label = response.data.data.label;
          this.placeholder = response.data.data.placeholder;
          this.hint = response.data.data.hint;
          this.isRequired = response.data.data.isRequired;
          this.inputType = response.data.data.inputType;
          this.inputOptions = response.data.data.inputOptions;
          this.isOnline = response.data.data.isOnline;
          this.colSize = response.data.data.colSize;

          this.edit = false;
        }
      } else {
        // name is not allowed
        alert(
          this.name +
            " als Name ist nicht erlaubt, da der Name einem Standard-Feld vorbehalten ist."
        );
      }
    },
  },

  computed: {},

  mixins: [apiRequest, helpers],
};
</script>
